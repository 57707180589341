import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Tab from '@material-ui/core/Tab';
import Spinner from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tabs from '../../components/CustomTabs';
import PropertyInfoCard from '../../components/PropertyInfoCard';
import TimeLine from '../../components/TimeLine';
import TimeLineItem from '../../components/TimeLineItem';
import {
  PropertyAccessSettings,
  PropertyDetails as PropertyDetailsType,
  PropertyDetailsInfo,
  PropertyInput,
} from '../../redux/types/properties';
import { HubLocation, HubLockLogType } from '../../redux/types/hubs';
import { estToUtc, formatToESTDate, toUTCDate } from '../../utils/date';
import { clearStorage } from '../../services/storage';
import { getHubInfoRequest } from '../../redux/actions/hubs/hubs';
import { PROPERTIES } from '../../constants/sortState';
import { Agent } from '../../redux/types/agents';
import IntegrationList from '../../components/Integrations';
import { PropertyTabs } from './types';
import HubPanel from '../../components/HubPanel';
import HubIcon from '../../components/Icons/HubIcon';
import styles from './styles';
import { ActionPayload } from '../../types/main';
import PropertyTourHours from '../../components/TourHours';
import { TourHoursType, WeeklyTourHours } from '../../redux/types/communities';
import Staff from '../../components/Staff';
import { Team } from '../../redux/types/teams';
import SmartLockPanel from '../../components/SmartLockPanel';
import HubLockLog from '../../components/HubLockLog';
import HubLockLogItem from '../../components/HubLockLogItem';
import { selectHubAccessHistoryFilters } from '../../redux/selectors/filter';
import HubLockLogMessage from '../../components/HubLockLogItem/HubLockLogMessage';
import PropertyImages from '../../components/PropertyImages';

type Props = {
  loadPropertyDetails: (propertyId: number | string) => void;
  hubInfo: any;
  propertyDetails: PropertyDetailsType | null;
  loading?: boolean;
  logsLoading?: boolean;
  isRental: boolean;
  isEnterprise: boolean;
  updatingProperties?: boolean;
  agents: Agent[];
  teams: Team[];
  lockLogs: HubLockLogType[];
  availableAgents: Agent[];
  loadAgents: () => void;
  loadTeams: () => void;
  loadTeamsByBuilder: (enterpriseId: number) => void;
  loadAvailableAgents: (builderId?: number) => void;
  saveProperty: (property: PropertyInput) => void;
  loadHubInfo: (hubId: ActionPayload<typeof getHubInfoRequest>) => void
  loadHubLockLog: (hubId: string, queryObject?: any) => void
  addAgent: (payload: {
    propertyId: number | string;
    agentId: string;
    is_project_manager: boolean | number,
    email: string,
    phone: string
  }) => void;
  removeAgent: (payload: {
    propertyId: number | string;
    agentId: string;
  }) => void;
  loadingAgents: boolean;
  is_project_manager: boolean | number;
  communityTourHours: TourHoursType[]
  propertyTourHours: TourHoursType[]
} & WithStyles<typeof styles> &
RouteComponentProps<{ propertyId: string }>;

const PropertyDetails: React.FC<Props> = (props) => {
  const {
    logsLoading,
    classes,
    hubInfo,
    lockLogs,
    loadPropertyDetails,
    propertyDetails,
    match,
    loadAgents,
    loadTeams,
    loadTeamsByBuilder,
    loadAvailableAgents,
    saveProperty,
    history,
    loadHubInfo,
    loadHubLockLog,
    teams,
    isRental,
    isEnterprise,
  } = props;

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tourHours, setTourHours] = useState<number[]>([5, 23]);
  const [propertyTourHours, setPropertyTourHours] = useState<WeeklyTourHours[] | null>(null);
  const [propertyAccessSettings, setPropertyAccessSettings] = useState<PropertyAccessSettings | null>(null);
  const [hubLocation, setHubLocationn] = useState<HubLocation>();
  const [assignedAgents, setAssignedAgents] = useState<Array<Agent>>([]);
  const [property, setProperty] = useState<PropertyDetailsInfo | null>(null);
  const [propertyBuilder, setPropertyBuilder] = useState<number | null>(null);
  const { startDate, endDate } = useSelector(selectHubAccessHistoryFilters);

  const handleAccessSettings = useCallback(
    (settings) => {
      setPropertyAccessSettings(settings);
    },
    [],
  );

  useEffect(() => {
    if (propertyDetails) {
      const agents = propertyDetails?.agents.map((agent) => ({
        username: agent.agent_username,
        firstname: agent.firstname,
        lastname: agent.lastname,
        phone: agent.phone,
        email: agent.email,
        is_project_manager: Boolean(agent.is_project_manager),
      }));
      setAssignedAgents(agents);
      setProperty({
        ...propertyDetails?.info,
        teams: propertyDetails?.teams,
        agents,
      });
    }
  }, [propertyDetails]);

  const showIntegrations = (integrations: any) => integrations.some((int: any) => int.isActive);
  const showIntegrationsTab = showIntegrations(propertyDetails?.integrations ?? []);
  const hubId = (propertyDetails && propertyDetails.info.hub_id) || null;
  const hubType = (propertyDetails && propertyDetails.info.hub_type) || null;
  const smartLockSerial = (propertyDetails && propertyDetails?.info.smart_lock_serial_number) || null;
  const isSeamType = (propertyDetails
      && propertyDetails?.info?.smart_lock_type?.toLowerCase() === 'seam')
    || null;

  const handleSave = () => {
    if (propertyDetails && propertyDetails.info) {
      const {
        property_id: propertyId,
        builder,
        disposition_id: dispositionId,
        house_num: houseNum,
        address_1: address1,
        community_id: communityId,
        subdivision,
        city,
        state,
        zipcode,
        latitude,
        longitude,
        price,
        bedrooms,
        halfBathrooms,
        fullBathrooms,
        squareFootage,
        isModel,
        lock_serial_number: lockSerialNumber,
        lock_status: lockStatus,
        contract_date: contractDate,
        timezone,
        url_1: url1,
        rental_application_url,
      } = propertyDetails.info;

      saveProperty({
        property_id: propertyId,
        builder,
        disposition_id: dispositionId,
        house_num: houseNum,
        address_1: address1,
        community_id: communityId,
        subdivision,
        city,
        state,
        zipcode,
        latitude,
        longitude,
        price,
        bedrooms,
        halfBathrooms,
        fullBathrooms,
        squareFootage,
        isModel,
        lock_serial_number: lockSerialNumber,
        lock_status: lockStatus,
        contract_date: contractDate,
        tourHoursStart: tourHours[0],
        tourHoursEnd: tourHours[1],
        timezone,
        url_1: url1,
        rental_application_url,
        propertyTourHours,
        access_settings: propertyAccessSettings?.accessSettings,
        relock_interval: propertyAccessSettings?.relockInterval,
        end_of_day_lockup: propertyAccessSettings?.endOfDayLockup,
        // property_backup_code: property?.property_backup_code,
        agents: property?.agents,
        teams: property?.teams,
      });
    }
  };

  const hours = [];

  for (let i = 5; i < 24; i += 1) {
    hours.push({ value: i, label: i < 12 ? `${i}am` : `${i === 12 ? i : i - 12}pm` });
  }

  useEffect(() => {
    loadPropertyDetails(match.params.propertyId);

    return () => {
      const pageNameIndex = 1;
      const newPathName = history.location.pathname.split('/')[pageNameIndex];

      if (newPathName !== 'properties') {
        clearStorage(PROPERTIES)();
      }
    };
  }, [
    loadPropertyDetails,
    match.params.propertyId,
    loadAgents,
    loadAvailableAgents,
    history.location.pathname,
  ]);

  useEffect(() => {
    if (!propertyBuilder) {
      return;
    }

    if (!isEnterprise) {
      loadTeams();
    }

    if (isEnterprise) {
      loadTeamsByBuilder(propertyBuilder);
    }
  }, [
    loadTeams,
    loadTeamsByBuilder,
    isEnterprise,
    propertyBuilder,
  ]);

  useEffect(() => {
    if (!propertyBuilder) {
      return;
    }

    loadAgents();
    loadAvailableAgents(propertyBuilder);
  }, [loadAgents, loadAvailableAgents, propertyBuilder]);

  useEffect(() => {
    if (property?.builder_id) {
      setPropertyBuilder(property?.builder_id);
    }
  }, [property]);

  useEffect(() => {
    if (
      propertyDetails?.info?.tourHoursStart
      && propertyDetails?.info?.tourHoursEnd
    ) {
      setTourHours([propertyDetails.info.tourHoursStart, propertyDetails.info.tourHoursEnd]);
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (hubId) {
      loadHubInfo(hubId);
      setHubLocationn(hubInfo.location);
    }
  }, [loadHubInfo, hubId, hubInfo, hubLocation]);

  useEffect(() => {
    if (smartLockSerial || hubId) {
      loadHubLockLog(hubId || smartLockSerial as string, {
        startDate: estToUtc(startDate, propertyDetails?.info.timezone, 'yyyy-MM-dd HH:mm:ss'),
        endDate: estToUtc(endDate, propertyDetails?.info.timezone, 'yyyy-MM-dd HH:mm:ss'),
      });
    }
  }, [loadHubLockLog, hubId, smartLockSerial, startDate, endDate, propertyDetails]);

  const groupLockLogs = useMemo(() => {
    const result: any = {};
    lockLogs.forEach((log) => {
      const keyDate = formatToESTDate(
        toUTCDate(log.created_on),
        Number(propertyDetails?.info.timezone),
        propertyDetails?.info.timezoneAbbr,
        'yyyy-MM-dd',
      );

      if (!(keyDate in result)) {
        result[keyDate] = [];
      }
      result[keyDate].push(log);
    });
    return result;
  }, [lockLogs, propertyDetails]);

  if (propertyDetails && propertyDetails.info.property_id !== Number(match.params.propertyId)) {
    return (
      <>
        <div className={classes.actionsBar}>
          <div className={classes.actionButtonsWrapper}>
            <Button
              variant="contained"
              className={classes.backToButton}
              component={Link}
              to="/properties"
            >
              <ArrowLeftIcon />
              Properties
            </Button>
          </div>
        </div>
        <div className={classes.progressWrapper}>
          <Spinner className={classes.progress} />
        </div>
      </>
    );
  }

  function handleChange(event: React.ChangeEvent<{}>, newTabIndex: PropertyTabs) {
    setTabIndex(newTabIndex);
  }

  const handleTourHours = (weeklyTourHours: WeeklyTourHours[] | null) => {
    setPropertyTourHours(weeklyTourHours);
  };

  const handleAddUsers = (agents: Agent[], teamIds?: number[]) => {
    if (property) {
      setProperty({
        ...property,
        agents,
        teams: teamIds ?? property?.teams,
      });
    }

    setAssignedAgents(agents);
  };

  return (
    <>
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/properties"
          >
            <ArrowLeftIcon />
            Properties
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid container spacing={4}>
          {propertyDetails && (
            <>
              <Grid item xs={12} md={4}>
                <PropertyInfoCard info={propertyDetails.info} />
              </Grid>
              <Grid item xs={12} md={8}>
                <div className={classes.rightSide}>
                  <Tabs value={tabIndex} onChange={handleChange} variant="scrollable">
                    <Tab
                      className={classes.tab}
                      label="Timeline"
                    />
                    <Tab
                      className={classes.tab}
                      label="Staff"
                    />
                    <Tab
                      className={classes.tab}
                      label="Automations"
                    />
                    <Tab
                      label="Integrations"
                      className={`${showIntegrationsTab ? '' : classes.hidden} ${classes.tab}`}
                    />
                    <Tab
                      className={classes.tab}
                      label="Tour hours"
                    />
                    <Tab
                      label="Gallery"
                      className={classes.tab}
                    />
                    <Tab
                      label="Hub"
                      className={`${clsx(classes.hubTab, {
                        [classes.hidden]: !hubId,
                      })} ${classes.tab}`}
                      icon={<HubIcon />}
                    />
                    <Tab
                      label={(
                        <>
                          <p>Access history</p>
                          <p className={classes.beta}>Beta</p>
                        </>
)}
                      className={`${clsx(classes.hubTab, {
                        [classes.hidden]: !hubId && !smartLockSerial,
                      })} ${classes.tab}`}
                      icon={<HistoryIcon style={{ marginRight: 0 }} />}
                    />
                    {smartLockSerial && (
                    <Tab
                      label="Smart Lock"
                      className={classes.tab}
                    />
                    )}
                  </Tabs>
                  <div className={classes.tabsContent}>
                    {tabIndex === PropertyTabs.LOCK_HISTORIES && (
                      <HubLockLog
                        loading={logsLoading}
                        onRefresh={() => loadHubLockLog(hubId || smartLockSerial as string, {
                          startDate: estToUtc(startDate, propertyDetails.info.timezone, 'yyyy-MM-dd HH:mm:ss'),
                          endDate: estToUtc(endDate, propertyDetails.info.timezone, 'yyyy-MM-dd HH:mm:ss'),
                        })}
                      >
                        {!!Object.keys(groupLockLogs).length && Object.keys(groupLockLogs).map((key, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={index} style={{ marginBottom: '12px' }}>
                            <p className={classes.groupLockTitle}>
                              {key}
                            </p>
                            {groupLockLogs[key]?.map((log: HubLockLogType) => (
                              <HubLockLogItem key={log.id} alignItems="flex-start">
                                <div className={clsx(classes.flex, classes.hubLogItem)}>
                                  <Typography className={classes.timestamp}>
                                    {formatToESTDate(toUTCDate(log.created_on), propertyDetails.info.timezone, '', 'h:mm a')}
                                  </Typography>
                                  <Typography className={classes.flex} style={{ gap: 0 }}>
                                    <span className={classes.lockIcon}>
                                      {log?.action.includes('unlock') ? <LockOpenIcon /> : <LockIcon />}
                                    </span>
                                    {' '}
                                    <HubLockLogMessage message={log} />
                                  </Typography>
                                </div>
                              </HubLockLogItem>
                            ))}
                          </div>
                        ))}
                      </HubLockLog>
                    )}
                    {tabIndex === PropertyTabs.TIMELINE && (
                      <TimeLine>
                        {propertyDetails.visits.map((visit) => (
                          <TimeLineItem
                            key={visit.customer_visit_id}
                            alignItems="flex-start"
                          >
                            <div>
                              <Typography className={classes.timestamp}>
                                {formatToESTDate(
                                  visit.modified_on,
                                  propertyDetails.info.timezone,
                                )}
                                {propertyDetails.info.timezoneAbbr}
                              </Typography>
                              <Typography>
                                {`${visit.firstname} ${visit.lastname}, ${visit.cell_phone_1}:`}
                              </Typography>
                              <Typography>{visit.remarks}</Typography>
                            </div>
                          </TimeLineItem>
                        ))}
                      </TimeLine>
                    )}
                    {tabIndex === PropertyTabs.AGENTS && (
                      <>
                        <Staff
                          handleAddUsers={handleAddUsers}
                          agents={assignedAgents}
                          teamIds={property?.teams}
                          teams={teams}
                        />
                        <div className={classes.btnWrap}>
                          <Button
                            onClick={handleSave}
                            className={classes.btnStyle}
                          >
                          Save
                          </Button>
                        </div>
                      </>
                    )}
                    {tabIndex === PropertyTabs.GALLERY && (
                      <PropertyImages propertyId={propertyDetails.info.property_id} />
                    )}
                    {tabIndex === PropertyTabs.AUTOMATIONS && (
                    <div>
                      <Grid container spacing={4}>
                        {/* <Grid item xs={6}>
                          <TextField
                            disabled
                            fullWidth
                            margin="normal"
                            id="temp_backup_code"
                            name="temp_backup_code"
                            label="Daily Backup Code"
                            value={property?.temp_backup_code || ''}
                          />
                        </Grid> */}
                        <Grid item xs={6}>
                          <TextField
                            disabled
                            fullWidth
                            margin="normal"
                            id="property_backup_code"
                            name="property_backup_code"
                            label="Property Master Code"
                            value={property?.property_backup_code || property?.community_backup_code || ''}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled
                            fullWidth
                            margin="normal"
                            id="trade_code"
                            name="trade_code"
                            label="Trade Code"
                            value={property?.trade_code || property?.community_trade_code || ''}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled
                            fullWidth
                            margin="normal"
                            id="sales_code"
                            name="sales_code"
                            label="Sales Code"
                            value={property?.sales_code || property?.community_sales_code || ''}
                          />
                        </Grid>
                        {isRental && (
                          <Grid item xs={6}>
                            <TextField
                              disabled
                              fullWidth
                              margin="normal"
                              id="resident_code"
                              name="resident_code"
                              label="Resident Code"
                              value={property?.resident_code || ''}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <div className={classes.btnWrap} style={{ marginTop: 20 }}>
                        <Button
                          onClick={handleSave}
                          className={classes.btnStyle}
                        >
Save
                        </Button>
                      </div>
                    </div>
                    )}
                    {showIntegrationsTab
                      && tabIndex === PropertyTabs.INTEGRATIONS && (
                        <div>
                          <IntegrationList
                            integrations={propertyDetails.integrations}
                            property_id={propertyDetails.info.property_id}
                          />
                        </div>
                    )}
                    {tabIndex === PropertyTabs.TOUR_HOUR && (
                      <Grid style={{ padding: '20px' }}>
                        <PropertyTourHours
                          variant="property"
                          handleTourHours={handleTourHours}
                          propertyHours={propertyDetails.propertyTourHours}
                          communityHours={propertyDetails.communityTourHours}
                        />
                        <div className={classes.btnWrap}>
                          <Button
                            onClick={handleSave}
                            className={classes.btnStyle}
                          >
                            Save
                          </Button>
                        </div>
                      </Grid>
                    )}
                    {tabIndex === PropertyTabs.HUB && (
                      <Grid>
                        {hubId && hubType && (
                        <HubPanel
                          hubId={hubId}
                          hubType={hubType}
                          handleSave={handleSave}
                          handleAccessSettings={handleAccessSettings}
                          property={propertyDetails?.info}
                        />
                        )}
                      </Grid>
                    )}
                    {tabIndex === PropertyTabs.SMART_LOCK && (
                      <Grid>
                        {smartLockSerial && isSeamType && (
                        <SmartLockPanel
                          smartLockSerial={smartLockSerial}
                          property={propertyDetails?.info}
                          handleSave={handleSave}
                          handleAccessSettings={handleAccessSettings}
                        />
                        )}
                      </Grid>
                    )}
                  </div>
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

export default React.memo(PropertyDetails);
